import {
  NDescriptions,
  NDescriptionsItem,
  NStatistic,
  NDivider,
} from "naive-ui";
import { Options, Vue } from "vue-class-component";
import {
  DesignIdeas16Filled,
  Production20Filled,
  DocumentBulletList24Filled,
} from "@vicons/fluent";

import VatValidator from "@/components/widgets/vat-validator/VatValidator.vue";
import ProductViewer from "@/components/widgets/product-viewer/ProductViewer.vue";
import router from "@/router";

import { namespace } from "vuex-class";
import { Component } from "vue";
import Ability from "@/ability";
import GeneralPermissions from "@/permissions/GeneralPermissions";

const app = namespace("app");

@Options({
  components: {
    NDescriptions,
    NDescriptionsItem,
    NStatistic,
    NDivider,
    DesignIdeas16Filled,
    Production20Filled,
    DocumentBulletList24Filled,
    VatValidator,
    ProductViewer,
  },
})
export default class DashBoard extends Vue {
  goTo(routeName: string) {
    router.push({ name: routeName });
  }

  @app.Getter
  public navs!: { name: string; icon: Component; route: string }[];

  @app.Getter
  public user!: { name: string; icon: Component; route: string }[];

  get dashboardNav() {
    let navArr = this.navs;

    if (!this.user) return [];

    if (!Ability.can(GeneralPermissions.editAdminPanel))
      navArr = navArr.filter((nav) => nav.name !== "Settings");

    return navArr;
  }
}
