// store/modules/user.ts
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

import {
  Home24Filled,
  DesignIdeas16Filled,
  Production20Filled,
  Settings16Filled,
  DocumentBulletList24Filled,
} from "@vicons/fluent";

import { shallowRef } from "vue";
import { INotification, User } from "@/interfaces/app";
import axios from "axios";
import Ability from "@/ability";
import GeneralPermissions from "@/permissions/GeneralPermissions";
import { Channel } from "laravel-echo";

@Module({ namespaced: true, name: "app" })
class App extends VuexModule {
  _navs = [
    {
      name: "Dashboard",
      icon: shallowRef(Home24Filled),
      route: "Dashboard",
    },
    {
      name: "Design Projects",
      icon: shallowRef(DesignIdeas16Filled),
      route: "IndexDesignProject",
    },
    {
      name: "Production Orders",
      icon: shallowRef(Production20Filled),
      route: "IndexProductionOrder",
    },
    {
      name: "Quotes",
      icon: shallowRef(DocumentBulletList24Filled),
      route: "IndexQuote",
    },
    {
      name: "Settings",
      icon: shallowRef(Settings16Filled),
      route: "SettingsPage",
    },
  ];

  _notifications: INotification[] = [];
  _channels: { name: string; channel: Channel }[] = [];

  _user?: User;

  get navs() {
    return this._navs;
  }

  get user() {
    return this._user;
  }

  get notifications() {
    return this._notifications;
  }

  @Mutation
  public SET_USER(user: User) {
    this._user = user;
  }

  @Mutation
  public SET_NOTIFICATIONS(notifications: INotification[]) {
    this._notifications = notifications;
  }

  @Mutation
  public ADD_NOTIFICATION(notification: INotification) {
    this._notifications.push(notification);
  }

  @Action({ rawError: true })
  public async fetchActiveUser(): Promise<void | unknown> {
    try {
      const [userResponse, notificationResponse] = await Promise.all([
        axios.get("/user"),
        axios.get("/notifications"),
      ]);

      // console.log(responses);

      this.SET_USER(userResponse.data.user);
      this.SET_NOTIFICATIONS(notificationResponse.data.notifications);
    } catch (e: unknown) {
      return e;
    }
  }

  @Action({ rawError: true })
  public async subscribeToChannel(name: string): Promise<App> {
    const index = this._channels.findIndex(
      (channelObj) => channelObj.name === name
    );

    if (index === -1) {
      const channel = await window.Echo.channel(name);
      this._channels.push({
        name: name,
        channel: channel,
      });

      return this;
    } else {
      return this;
    }
  }

  @Action({ rawError: true })
  public channelEventListener({
    channel,
    event,
    callback,
  }: {
    channel: string;
    event: string;
    callback: (...args: any[]) => void;
  }) {
    console.log({
      channel,
      event,
      callback,
    });

    const index = this._channels.findIndex(
      (channelObj) => channelObj.name === channel
    );

    if (index === -1) throw "Channel Not found";

    this._channels[index].channel.listen(`.${event}`, callback);
  }

  @Action({ rawError: true })
  public pushNotification(notification: INotification) {
    this.ADD_NOTIFICATION(notification);
  }

  @Action({ rawError: true })
  public async flagAsRead(notifications: INotification[]) {
    const notificationIds = notifications.map(
      (notification) => notification.id
    );

    const formData = new FormData();
    formData.append("notification_ids", JSON.stringify(notificationIds));

    const response = await axios.post("/notifications/flag_as_read", formData);

    response.data.notifications.forEach((notification: INotification) => {
      const index = this.notifications.findIndex(
        (n) => n.id === notification.id
      );

      this.notifications[index] = JSON.parse(JSON.stringify(notification));
    });
  }
}
export default App;
